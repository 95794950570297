require("../css/app.less");

require("@babel/polyfill");

import * as Sentry from '@sentry/browser';
Sentry.init({ dsn: 'https://b83bdae98d0a4761bdc5b07c2e0f9c75@sentry.io/1409326' });

require("jquery");
require("bootstrap");

window.number_format = function number_format(number, decimals, dec_point, thousands_sep) {
	number = (number + '')
		.replace(/[^0-9+\-Ee.]/g, '');
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
		dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function(n, prec) {
			var k = Math.pow(10, prec);
			return '' + (Math.round(n * k) / k)
				.toFixed(prec);
		};
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
		.split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
	}
	if ((s[1] || '')
		.length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1)
			.join('0');
	}
	return s.join(dec);
}

let $body = $("body");
let $modelBody = $(".modal-body");
$body.on("click", "#modal", function () {
	if ($("#modal").data("href") === "rembourssement") {
		let path = $("[data-remboursement-attente-url]").data("remboursement-attente-url");
		$modelBody.html('');
		$("#modal-personnaliser").modal('show');
		$(".modal-title").html("Liste des remboursements en attente");
		$modelBody.append("<div id='devisProcessLoad' class='text-center'><p><i class='fa fa-cog fa-spin  fa-3x'></i> <strong> Le " +
			" traitement est en cours, veuillez patienter s il vous plait.</strong></p></div>");
		const now = new Date();
		
		$.ajax({
			url: path,
			type: 'POST',
			success: function (resultat) { // code_html contient le HTML renvoyé
				
				$modelBody.html(
					"<table class='table table-bordered'>" +
					"<thead>" +
					"<tr>" +
					"<th>Code client</th>" +
					"<th>Nom</th>" +
					"<th>Prénom</th>" +
					"<th>Code Contrat</th>" +
					"<th>Montant</th>" +
					"<th>Date règlement prévu</th>" +
					"</tr>" +
					"</thead>" +
					"<tbody class='contenu'>" +
					"</tbody>" +
					"</table>"
				);
				if (resultat.length > 0) {
					$.each(resultat, function (key, value) {
						let mois = value.dateReglementDecompo[1];
						let date = new Date(value.dateReglementDecompo[0], mois - 1, value.dateReglementDecompo[2]);
						let color = date <= now ? 'error' : 'green';
						
						$('.contenu').append(
							"<tr class='" + color + "'>" +
							//"<td><a  data-client=" + value.cliCodeClient + "  href=" + url + " class='choisir'> <i class='fa fa-check-square-o'></i> Choisir</a></td>" +
							"<td class='text-center'>" + value.codeClient + "</td>" +
							"<td class='text-center'>" + value.nom + "</td>" +
							"<td class='text-center'>" + value.prenom + "</td>" +
							"<td class='text-center'>" + value.codeContrat + "</td>" +
							"<td class='text-right'>" + value.soldeQuittance + " €</td>" +
							"<td class='text-center'>" + value.dateReglement + "</td>" +
							"</tr>"
						);
					});
					
					return true;
				} else {
					$('.modal-title').html("Liste remboursement en attente");
					$('.contenu').append("<tr><td  colspan='7' class='text-center'> Pas de résultat</td></tr>");
					return false;
				}
			}
		});
	}
});

$('[data-toggle="tooltip"]').tooltip();

$("#agendageHabilitation").on("submit", function (event) {
	let accredit = $('#testacreditation_accreditation').val();
	if (accredit.length > 1) {
		$.ajax({
			url: $('[data-accred-agendage-url]').data('accred-agendage-url'),
			type: 'POST',
			dataType: 'json',
			beforeSend: function (xhr) {
				$("#accreditAlert").addClass('hidden');
				$("#accreditSuccess").addClass('hidden');
				$("#divFormAccredit").addClass('hidden');
				$("#spinerAccredit").removeClass("hidden");
			},
			complete: function (jqXHR, textStatus) {
				//$("#iconeLoadAjax").addClass('hidden');
				//$("#inputAjaxCodeContrat").removeClass("hidden");
			},
			data: {accredit: accredit},
			success: function (jsonData) {
				if (jsonData.result) {
					if (jsonData.alert) {
						$("#accreditAlert").removeClass('hidden');
						$("#messageErrorAnnulationQuittance").text(jsonData.message);
						$("#divFormAccredit").removeClass('hidden');
					} else {
						$modelBody.empty();
						$modelBody.append(jsonData.html).show('slow');
					}
				} else {
				
				}
			}
		}).done(function () {
			$("#spinerAccredit").addClass("hidden");
		});
	}
	return false;
});

$body.on("click", "#grossiste", function () {
	let path = $("[data-ajax-courtiers-references]").data("ajax-courtiers-references");
	$(".modal-title").html("Courtiers référencés");
	$modelBody.html("<div id='devisProcessLoad' class='text-center'><p><i class='fa fa-cog fa-spin  fa-3x'></i> <strong> Le " +
		" traitement est en cours, veuillez patienter s il vous plait.</strong></p></div>");
	
	$.ajax({
		url: path,
		type: 'POST',
		success: function (resultat) { // code_html contient le HTML renvoyé
			$modelBody.html("<div class='contenu'></div>");
			
			if (resultat.length > 0) {
				$.each(resultat, function (key, value) {
					$('.contenu').append(
						"<div class='row'>"+
						"<div class='col-lg-4 form-group text-center'>" +
						value.cou_code_courtier +
						"</div>" +
						"<div class='col-lg-2 form-group text-center'>" +
						value.cou_orias +
						"</div>" +
						"<div class='col-lg-2 form-group text-center'>" +
						value.cou_sigle +
						"</div>" +
						"<div class='col-lg-2 form-group text-center'><img src='/resources/images/logo/" +
						value.cou_logo +
						"' width='30' height='30'/></div>" +
						"<div class='col-lg-2 form-group  text-center'>" +
						value.cou_rs_cou +
						"</div>" +
						"</div>"
					);
				});
				
				return true;
			} else {
				$('.contenu').append("<tr><td  colspan='7' class='text-center'> Pas de résultat</td></tr>");
				return false;
			}
		}
	});
});